import AssetDTO from "@/classes/asset/AssetDTO";
import {AssetType} from "@/classes/asset/AssetType";

export default class AssetDTOWithType extends AssetDTO {

    //ToDo: change back to assetType AssetDTO contains type already and this is something else
    /**
     *
     * @type {AssetType}
     */
    type = AssetType;

    /**
     *
     * @param {Object} asset
     * @param {AssetType} assetType
     */
    constructor(asset, assetType) {
        super(asset);
        this.type = assetType
    }
}

