import {apiServiceVue} from "@/services/apiService.vue";
import WizardContract from "@/services/contracts/wizardContract";
import AssetDTOWithType from "@/classes/asset/AssetDTOWithType";
import {AssetType} from "@/classes/asset/AssetType";
import EnhancementContract from "@/services/contracts/enhancementContract";
import LandContract from "@/services/contracts/landContract";
import CardContract from "@/services/contracts/cardContract";

export default class AssetService {
    /**
     * @type {{
     *             balance: number,
     *             isWalletConnected: boolean,
     *             address: number,
     *         }}
     */
    #web3;
    #flashMessage;
    #contractByAssetType;

    constructor(web3, flashMessage) {
        this.#web3 = web3;
        this.#flashMessage = flashMessage;
    }

    /**
     * @param {AssetType} assetType
     * @returns {NftAbstractContract}
     */
    getContractByAssetType(assetType) {
        if (!this.#contractByAssetType) {
            this.#contractByAssetType = new Map([
                [AssetType.WIZARD, new WizardContract(this.#web3)],
                [AssetType.LAND, new LandContract(this.#web3)],
                [AssetType.ENHANCEMENT, new EnhancementContract(this.#web3)],
                [AssetType.CARD, new CardContract(this.#web3)],
            ])
        }
        return this.#contractByAssetType.get(assetType);
    }

    /**
     * @param {AssetDTOWithType} assetDTO
     */
    async getOwnerOf(assetDTO) {
        if (assetDTO.type === AssetType.ENHANCEMENT) {
            return this.#web3.address;
        } else {
            return await this.getContractByAssetType(assetDTO.type)
                .ownerOf(assetDTO.id);
        }
    }

    /**
     *
     * @return {Promise<AssetDTOWithType[]>}
     */
    async fetchMyAssets(assetTypes = []) {
        let promises = [];

        if (assetTypes.includes(AssetType.WIZARD)) {
            promises.push(this.fetchMyWizards());
        }

        if (assetTypes.includes(AssetType.LAND)) {
            promises.push(this.fetchMyLands());
        }

        if (assetTypes.includes(AssetType.ENHANCEMENT)) {
            promises.push(this.fetchMyEnhancements());
        }

        const res = await Promise.all([...promises]);

        return res?.flat() || [];
    }

    /**
     * @return {Promise<AssetDTOWithType[]>}
     */
    async fetchMyWizards() {
        if (!this.#web3.isWalletConnected) {
            this.#showError('Wallet not connected. Unable to fetch user wizards');
            return [];
        }

        return await this.fetchWizardsOf(this.#web3.address);
    }

    /**
     * @return {Promise<AssetDTOWithType[]>}
     */
    async fetchWizardsOf(walletAddress) {
        let wizards = [];

        const url = process.env.VUE_APP_WIZARD_NFT_SERVER_URL + 'wallet/' + walletAddress;
        await apiServiceVue.get(url, {limit: 500, offset: 0})
            .then(res => {
                wizards = res.data || [];
            })
            .catch((e) => {
                console.error(e);
                this.#showError('Fetch Wizards failed')
            });

        return wizards.map(rawWizard => new AssetDTOWithType(rawWizard, AssetType.WIZARD));
    }

    /**
     * @return {Promise<AssetDTOWithType[]>}
     */
    async fetchMyLands() {
        if (!this.#web3.isWalletConnected) {
            this.#showError('Wallet not connected. Unable to fetch user lands');
            return [];
        }

        return await this.fetchLandsOf(this.#web3.address);
    }

    /**
     * @return {Promise<AssetDTOWithType[]>}
     */
    async fetchLandsOf(walletAddress) {
        const url = process.env.VUE_APP_LAND_NFT_SERVER_URL + 'wallet/' + walletAddress;
        return apiServiceVue.get(url, {limit: 500, offset: 0})
            .then(res => {
                if (res && res.data) {
                    return res.data.map(rawLand => new AssetDTOWithType(rawLand, AssetType.LAND));
                } else {
                    this.#showError('Fetch Lands failed')
                }
            })
            .catch((e) => {
                console.error(e);
                this.#showError('Fetch Lands failed')
            });
    }

    /**
     *
     * @return {Promise<AssetDTOWithType[]>}
     */
    async fetchMyEnhancements() {
        const enhancementContract = new EnhancementContract(this.#web3);
        let finalEnhancements = [];

        await enhancementContract.getMyBalanceOfEnhancementsBatch()
            .then(async (res) => {
                let ids = [];
                res.forEach((amount, id) => {
                    if (amount > 0) {
                        ids.push(id);
                    }
                });
                if (ids.length > 0) {
                    const url = process.env.VUE_APP_ENHANCEMENT_NFT_SERVER_URL + ids;
                    let enhancements = [];
                    await apiServiceVue.request(url).then((res2) => {
                        ids.forEach((id) => {
                            // empty item (show anything in case missing in DB)
                            let enh = {
                                name: '#' + id,
                                description: '?',
                                img: ''
                            };
                            if (res2.hasOwnProperty(id)) {
                                enh = res2[id];
                                enh.amount = res[id];
                            }
                            enhancements.push(enh);
                        });
                    });
                    finalEnhancements = enhancements;
                }
            })
            .catch((error) => {
                console.error(error);
            })
        return finalEnhancements.map(enh => new AssetDTOWithType(enh, AssetType.ENHANCEMENT))
    }


    #showError(message) {
        if (!!this.#flashMessage) {
            this.#flashMessage.show({
                status: 'error',
                message: message
            });
        } else {
            console.error(message)
        }
    }
};
